<template>
    <div class="pages flex column align_center justify_center error_404">
        <img class="icon" src="../../assets/error.gif" @click="onBackIndex" />
        <h2>您访问的页面不存在</h2>
        <i-button type="primary" class="btn_back mt_20" @click="onBackIndex">返回首页</i-button>
    </div>
</template>
<script>
export default {
    name: 'error_404',
    data() {
        return {
            //
        };
    },
    methods: {
        onBackIndex() {
            this.goUrl('/index');
        },
    },
};
</script>
<style lang="less" scoped>
.error_404 {
    color: #9c9c9c;
    .icon {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }
    h2 {
        font-size: 34px;
    }
}
</style>
